<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Thêm nhân sự xử lý</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-autocomplete v-model="id_warehouse" :items="warehouses"
                      @change="getEmployees"
                      label="Chọn kho*" required dense></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete :error="validates.includes('id_member')"
                      v-model="id_member" :items="employees"
                      label="Chọn nhân sự*" required dense></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <small v-if="errMsg" class="red--text">{{ errMsg }}</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDialog">
        Đóng
      </v-btn>
      <v-btn color="primary" @click="addMemberRequest">
        Lưu
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {httpClient} from "@/libs/http";

export default {
  name: "AddEmployee",
  props: {
    idInternalRequest: {
      type: Number,
      default: () => 0
    },
  },
  data: () => ({
    errMsg: null,
    id_warehouse: null,
    id_member: null,
    validates: [],
    warehouses: [],
    employees: [],
    isLoading: false,
  }),
  methods: {
    async addMemberRequest() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      this.errMsg = null
      this.validates = []
      try {
        await httpClient.post('/internal-request-add-member', {
          id_member: this.id_member,
          id_internal_request: this.idInternalRequest
        })
        this.id_member = null
        this.closeDialog()
        this.refreshData()
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        this.errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        const validates = e.response && e.response.data && e.response.data.error && e.response.data.error.validates || []
        if (validates && validates.length > 0) {
          validates.forEach(v => {
            this.validates.push(v.key)
          })
        }
      }
    },
    async getWarehouses() {
      const {data} = await httpClient.post('/employee-active-my-pos')
      this.warehouses = data.map(e => ({
        value: e.id,
        text: e.code_name || ''
      }))
    },
    /*async getWarehouses() {
      if (!window.me || !window.me.warehouses) {
        this.warehouses = []
        return false
      }
      this.warehouses = window.me.warehouses.map(e => ({
        value: e.id,
        text: e.code_name || ''
      }))
    },*/
    async getEmployees() {
      let data = []
      if (this.id_warehouse) {
        data = await httpClient.post('/employee/v1/get-by-warehouse', {
          id_warehouse: this.id_warehouse
        })
      } else {
        data = await httpClient.post('/employee/v1/get-by-my-warehouse', {
          id_warehouse: this.id_warehouse
        })
      }
      this.employees = data.data.map(e => ({
        value: e.id_identity,
        text: e.full_name || ''
      }))
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    refreshData() {
      this.$emit('refreshData')
    },
    removeValidate(name) {
      this.validates = this.validates.filter(v => v !== name)
    },
  },
  mounted() {
    this.getWarehouses()
    this.getEmployees()
  }
}
</script>

<style scoped>

</style>
